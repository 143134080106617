/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { CSSTransition } from 'react-transition-group'

// Images
import imgLogo from 'img/favicon.png'
import linkedinIcon from 'img/linkedin_icon.svg'
import phone from 'img/phone.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'
import ButtonLine from 'components/elements/ButtonLine'

const Svg = styled.svg`
  z-index: 199;
`

const StyledHeader = styled.nav`
  background-color: ${props => props.theme.color.secondary};
  position: relative;
  padding: 0 0;
`

const StyledTopHeader = styled.div`
  background-color: ${props => props.theme.color.contrast};
  height: 50px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Brand = styled(CustomLink)`
  img {
    height: 55px;
    width: 143px;
  }

  @media screen and (max-width: 991px) {
    img {
      height: 30px;
      width: 90px;
    }
  }
`

const NavButton = styled.button`
  position: absolute;
  top: 17px;
  right: 20px;
  width: 24px;
  height: 24px;
  padding: 0;

  ${Svg} {
    width: 100%;
    height: 100%;
  }
`

const LinkedinLink = styled.a`
  img {
    transition: 0.25s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const Header = () => {
  const {
    allWordpressMenusMenusItems: { edges },
    header
  } = useStaticQuery(graphql`
    {
      allWordpressMenusMenusItems(filter: { wordpress_id: { eq: 2 } }) {
        edges {
          node {
            items {
              title
              url
              wpId: wordpress_id
            }
          }
        }
      }

      header: wordpressWpComponent(wordpress_id: { eq: 94 }) {
        acf {
          phonenumber
          linkedin
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }


    }
  `)

  const { items } = edges[0].node

  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <StyledHeader>
      <StyledTopHeader className="d-none d-lg-block">
        <div className="container d-flex justify-content-end align-items-center h-100">
          <ButtonDefault isAnchor to={`tel:${header.acf.phonenumber}`} className="mr-4">
            <img className="mr-2" src={phone} alt="" />
            <p className="mb-0">{header.acf.phonenumber}</p>
          </ButtonDefault>
          <LinkedinLink target="_blank" rel="noreferrer" href={header.acf.linkedin}>
            <img src={linkedinIcon} alt="" />
          </LinkedinLink>
        </div>
      </StyledTopHeader>
      <Container className="container py-3">
        <Brand to="/">
          <img loading="eager" fadeIn={false} src={imgLogo} alt="" />
        </Brand>

        <NavButton
          type="button"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="d-block d-lg-none"
        >
          <Svg 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="fas"
            data-icon="bars" 
            className="svg-inline--fa fa-bars fa-w-14" 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 448 512"
          >
            <path 
              fill="#006CB2" 
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" 
            />
          </Svg>
        </NavButton>

        <HeaderMenuMobile
          items={items}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          image={header.acf.image}
          phonenumber={header.acf.phonenumber}
        />
        <HeaderMenuDesktop items={items} />
      </Container>
    </StyledHeader>
  )
}

const StyledTransition = styled(CSSTransition)``

const StyledHeaderMenuMobile = styled.div`
  background-color: ${props => props.theme.color.main};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Ul = styled.ul`
  padding-left: 50px;
  padding-right: 50px;

  list-style: none;
`

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;

  position: absolute !important;
  z-index: -10;
`

const PhoneLI = styled.li``

const StyledButtonLine = styled(ButtonLine)`
  background-color: ${props => props.theme.color.text.light};

  p {
    font-size: ${props => props.theme.font.size.xm};
  }
`

const HeaderMenuMobile = ({ items, isCollapsed, setIsCollapsed, image, phonenumber }) => (
  <StyledTransition in={!isCollapsed} unmountOnExit>
    <StyledHeaderMenuMobile>
      <NavButton
        type="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Svg 
          aria-hidden="true" 
          focusable="false" 
          data-prefix="fas" 
          data-icon="times" 
          className="svg-inline--fa fa-times fa-w-11" 
          role="img" 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 352 512"
        >
          <path 
            fill="#FFFFFF" 
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" 
          />
        </Svg>
      </NavButton>

      <Ul>
        <HeaderMenuItems items={items} />
        <PhoneLI className="d-flex justify-content-center pt-3">
          <StyledButtonLine className="py-1" isAnchor to={`tel:${phonenumber}`}>
            <p className="mb-0 px-1 color-text-main font-weight-xl">{phonenumber}</p>
          </StyledButtonLine>
        </PhoneLI>
      </Ul>
      <StyledImg fluid={image.localFile.childImageSharp.fluid} alt="" />
    </StyledHeaderMenuMobile>
  </StyledTransition>
)

const StyledHeaderMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const HeaderMenuDesktop = ({ items }) => (
  <StyledHeaderMenuDesktop className="d-none d-lg-flex">
    <HeaderMenuItems items={items} />
  </StyledHeaderMenuDesktop>
)

const Item = styled.li`
  & > a {
    color: ${props => props.theme.color.text.main};
    font-size: ${props => props.theme.font.size.sm};

    &[aria-current] {
      font-weight: ${props => props.theme.font.weight.xl};
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 15px;

    & > a {
      color: ${props => props.theme.color.text.light};
      font-size: ${props => props.theme.font.size.l};
    }
  }
`

const HeaderMenuItems = ({ items }) => (
  <>
    {items.map(({ url, title, wpId }) => (
      <Item key={wpId}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
