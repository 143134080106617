/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import Image from 'components/elements/Image';

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const StyledHero = styled.section`
  position: relative;

  display: flex;
  align-items: center;
`

const StyledImage = styled(Image)`
  width: 100%;

  ${props => props.home ? css`
    height: 555px;

    @media screen and (max-width: 991px) {
      height: 450px;
    }

    @media screen and (max-width: 575px) {
      height: 400px;
    }

    @media screen and (max-width: 430px) {
      height: 350px;
    }

    @media screen and (max-width: 325px) {
      height: 380px;
    }

  ` : css`
    height: 230px;

    @media screen and (max-width: 991px) {
      height: 200px;
    }

    @media screen and (max-width: 768px) {
      ${props.bigger ? css`
        height: 200px;
      `: css`
        height: 170px;
      `}
    }

    @media screen and (max-width: 429px) {
      ${props.bigger ? css`
        height: 130px;
      `: css`
        height: 130px;
      `}
    }

    @media screen and (max-width: 390px) {
      ${props.bigger ? css`
        height: 170px;
      `: css`
        height: 140px;
      `}
    }
  `}
`

const ContainerContent = styled.div`
  width: 55%;

  @media screen and (max-width: 991px) {
    width: 70%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  p {
    font-size: ${props => props.theme.font.size.xl};
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.s};
    line-height: 37px;

    @media screen and (max-width: 575px) {
      font-size: ${props => props.theme.font.size.l};
      line-height: 30px;
    }

    @media screen and (max-width: 430px) {
      font-size: ${props => props.theme.font.size.xm};
      line-height: 25px;
    }
  }

  h3 {
    font-size: ${props => props.theme.font.size.xxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xl};
    }

    @media screen and (max-width: 430px) {
      font-size: ${props => props.theme.font.size.l};
    }
  }
`

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  ${props => props.home ? css`
    ${props.detail ? css`
      top: 50px;

      @media screen and (max-width: 991px) {
        top: 30px;
      }
    ` : css`
      top: 70px;

      @media screen and (max-width: 991px) {
        top: 30px;
      }
    `}
  ` : css`
    /* Nothing */
  `}

  

  h2 {
    font-size: ${props => props.theme.font.size.xm};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.m};
    }

    @media screen and (max-width: 430px) {
      font-size: ${props => props.theme.font.size.sm};
    }
  }

  h1 {
    font-size: ${props => props.theme.font.size.xxml};

    ${props => props.detail ? css`
      @media screen and (max-width: 768px) {
        font-size: ${props.theme.font.size.xxl};
      }

      @media screen and (max-width: 430px) {
        font-size: ${props.theme.font.size.xl};
      }
    ` : css`
      @media screen and (max-width: 430px) {
        font-size: ${props.theme.font.size.xxl};
      }
    `}
  }
`

const Hero = ({ className, content, home, detail, bigger }) => (
  <StyledHero className={`position-relative ${className ? `${className}` : ``}`}>
    <StyledImage bigger={bigger} home={home} src={content.image} />
    <Container home={home} detail={detail} className="container">
      <h2 className="mb-0 text-uppercase color-text-light font-weight-s">Systeemvloeren service bunschoten b.v.</h2>
      <h1 className="mb-0 text-uppercase font-weight-xl color-text-light">{content.title}</h1>
      {home && (
        <ContainerContent className="pt-sm-5 pt-4">
          {parse(content.text)}
        </ContainerContent>
      )}
      {detail && (
        <ContainerContent>
          <h3 className="color-text-light text-uppercase font-weight-s">{content.sub_title}</h3>
        </ContainerContent>
      )}
    </Container>
  </StyledHero>
)

export default Hero
