/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Layout = ({ children }) => (
  <Theme>
    <Header />
    <>{children}</>
    <Footer />
  </Theme>
)

export default Layout
