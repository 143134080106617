/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.light};
  border: 1px solid ${props => props.theme.color.border};
  display: inline-block;
  border-radius: 19.5px;
  cursor: pointer;
  transition: 0.25s;
  
  & > a, & > button {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.sm};
    padding: 0 12px;
    width: 100%;
    min-height: 31px;
    height: 100%;
    display: block;

    &:hover {
      color: ${props => props.theme.color.text.light};
    }
  }

  &:hover {
    transform: scale(1.05);
  }
`

const ButtonLine = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell className="d-flex align-items-center" to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonLine