/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import phone from 'img/phone.svg'
import linkedin from 'img/white_linkedin_icon.svg'
import SSBLogo from 'img/SSB_logo_original.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: ${props => props.theme.color.gray};
  color: ${props => props.theme.color.text.secondary};

  h2 {
    font-size: ${props => props.theme.font.size.xm};
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      height: 2px;
      width: 145px;
      background-color: ${props => props.theme.color.secondary};
    }
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
    font-weight: ${props => props.theme.font.weight.s};
  }

  a {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const StyledCustomLink = styled(CustomLink)`
  p {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 5px;
      width: 5px;
      left: 0;
      top: 7px;
      border-radius: 10px;
      background-color: ${props => props.theme.color.secondary};
    }
  }

  &:hover {
    p {
      text-decoration: underline;
    }
  }
`

const Contact = styled.div`
  p, a {
    font-weight: ${props => props.theme.font.weight.xl};
  }

  a:hover {
    color: ${props => props.theme.color.contrast};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  &:hover {
    p {
      color: ${props => props.theme.color.secondary};
    }
  }

  p {
    font-weight: ${props => props.theme.font.weight.s};
  }
`

const UpFooter = styled.div`
  border-top: 4px solid ${props => props.theme.color.main};

  h3 {
    font-size: ${props => props.theme.font.size.l};
  }
`

const Footer = () => {
  const {
    footer: {
      acf: footer
    }
  } = useStaticQuery(graphql`{
    footer: wordpressWpComponent(wordpress_id: {eq: 95}) {
      ...FooterFrag
    }
  }`)

  return (
    <>
      <UpFooter>
        <div className="container py-4">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <img className="mr-lg-5 mb-lg-0 mb-4" src={SSBLogo} alt="" />
            <h3 className="mb-0 text-center text-uppercase color-text-blue font-weight-xl">Systeemvloeren Service Bunschoten B.V.</h3>
          </div>
        </div>
      </UpFooter>
      <StyledFooter>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 col-lg-4 px-5 px-lg-0 mb-lg-0 mb-5">
              <h2 className="mb-5 color-text-light text-uppercase font-weight-xl">Menu</h2>
              {footer.menu.map((item, index) => (
                <StyledCustomLink to={item.link.url} key={index}>
                  <p className="pl-4 color-text-light">{item.link.title}</p>
                </StyledCustomLink>
              ))}
            </div>
            <div className="col-md-6 col-lg-4 px-5 px-lg-0 mb-lg-0 mb-5">
              <h2 className="mb-5 color-text-light text-uppercase font-weight-xl">Diensten</h2>
              {footer.diensten.map((item, index) => (
                <StyledCustomLink to={item.link.url} key={index}>
                  <p className="pl-4 color-text-light">{item.link.title}</p>
                </StyledCustomLink>
              ))}
            </div>
            <Contact className="col-md-12 col-lg-4 px-5 px-lg-0 mb-lg-0 mb-5">
              <h2 className="mb-5 color-text-light text-uppercase font-weight-xl">Contact</h2>
              <p className="color-text-light">{footer.contact.name}</p>
              <p className="color-text-light">{footer.contact.address}</p>
              <p className="pb-4 color-text-light">{footer.contact.zipcode}</p>
              <a href={`mailto:${footer.contact.email}`} className="color-text-light">{footer.contact.email}</a>
              <div className="py-3 d-flex align-items-center">
                <StyledButtonDefault isAnchor to={`tel:${footer.contact.phonenumber}`}>
                  <img className="mr-2" src={phone} alt="" />
                  <p className="mb-0">{footer.contact.phonenumber}</p>
                </StyledButtonDefault>
                <StyledCustomLink className="ml-3" to={footer.contact.linkedin}>
                  <img src={linkedin} alt="" />
                </StyledCustomLink>
              </div>

            </Contact>
          </div>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer
